import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hiringStyles from "./hiring.module.css"
// import chairImage from "../images/chair-contactpage.png"

const HiringPage = ({ data }) => (
    <Layout>
        <SEO title="Policies" />
        <div className={hiringStyles.backgroundColor}></div>
        <div className={hiringStyles.policySection}>
            <h1>Policies</h1>
            <h2>Children</h2>
            <p>Children must always be with their parents. If you are seated, your child must be seated with you. If your child is walking around, you must be walking around with them.</p>
            <h2>Dogs</h2>
            <p>Leashed dogs that are calm and quiet are welcome inside and outside. Dogs that excessively bark or display aggression may be asked to leave.</p>
            <p>No other animals are allowed.</p>
            {/* <h1>Stodgy Brewing Company is Hiring a Part-Time Bartender!</h1>
            <p>Join our small team of dedicated beer enthusiasts. We're looking for folks that would 
                love a part-time bartending job. Service industry or customer service experience is a 
                big plus. You must be able to be quick, efficient, like beer and be willing to learn 
                lots about it, and be able to work weekends and holidays. All applicants must be over 21.</p>
            <p>To apply, please come in person during business hours. Bring your resume with 3 references and a cover letter that answers the following questions:</p>
            <ol>
                <li>Why do you want to work at Stodgy (besides money)?</li>
                <li>What excites you in life (besides beer)?</li>
                <li>What's your weekly availability?</li>
            </ol> */}
        </div>
    </Layout>
)

export default HiringPage
